























































































import { AppUser } from "@/models/user";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Logo from "@/components/Logo.vue";
import isJwtTokenExpired from "jwt-check-expiry";

const namespace = "app";
@Component({ components: { Logo } })
export default class ToolBar extends Vue {
  @Getter("getAppName", { namespace })
  private appName!: string;
  // @Getter("authenticated", { namespace })
  // private authenticated!: boolean;
  @Getter("getLeftDrawer", { namespace })
  private leftDrawer!: boolean;
  @Action("logout", { namespace })
  private logout: any;
  @Action("toggleLeftDrawer", { namespace })
  private toggleLeftDrawer: any;
  @Getter("user", { namespace })
  private user!: AppUser;
  @Getter("getSticky", { namespace })
  private leftSticky!: boolean;
  @Action("toggleSticky", { namespace })
  private toggleLeftSticky: any;

  search = "";
  get drawer() {
    return this.leftDrawer;
  }
  get authenticated() {
    const token = window.localStorage.getItem("jwt");
    return token && !isJwtTokenExpired(token as string);
  }
  set drawer(value: boolean) {
    this.toggleLeftDrawer();
  }
  get sticky() {
    return this.leftSticky;
  }
  set sticky(value: boolean) {
    this.toggleLeftSticky();
  }
  get StickIcon() {
    return this.sticky ? "chevron_left" : "chevron_right";
  }
  get usernameFirstLetter() {
    return this.userName.charAt(0);
  }
  get loading() {
    return this.user === null || this.user == undefined;
  }
  get userName() {
    if (this.user === null || this.user == undefined) {
      return "Please login";
    }
    return this.user.userName;
  }
  goToLogin() {
    this.logout();
    this.$router.push("/");
  }
}
