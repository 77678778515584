import Audit from "@/models/audit";
import FormR from "@/models/formR";
import {
  ApproveApplicationVM,
  BusinessApplication,
  BusinessProfileVM,
} from "@/models/businessApplication";
import {
  BusinessDocument,
  BusinessDocumentType,
  DocumentProfile,
  DocumentStatus,
  ProfileDocument,
} from "@/models/businessDocument";
import ClaimDTO from "@/models/claim";
import FetchData from "@/models/FetchData";
import FinancialInstitution from "@/models/financialInstitution";
import Role from "@/models/role";
import { AppUser, RegisterDTO, ResetPasswordVM } from "@/models/user";
import router from "@/router";
import axios, { AxiosError, AxiosResponse } from "axios";
import Notifier from "./notifier";
import Comment, { CommentVM } from "@/models/comment";
import Portfolio, { UpdatePortfolioDTO } from "@/models/portfolio";
import FormT from "@/models/formT";
import UsageStatistics from "@/models/statistics";
import { format } from "date-fns";
import RejectVM from "@/models/reject";

// const sleep = (delay: number) => {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay)
//     })
// }

axios.defaults.baseURL = "/api";

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("jwt");
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    console.log(error);
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data == "string") {
          Notifier.showError(data);
        }
        if (
          config.method === "get" &&
          Object.prototype.hasOwnProperty.call(data.errors, "id")
        ) {
          router.push("/notfound");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
              Notifier.showError(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        Notifier.showError("unauthorized");
        break;
      case 404:
        router.push("/notfound");
        Notifier.showError("not found");
        break;
      case 500:
        //   store.commonStore.setServerError(data);
        //   history.push('/server-error')
        Notifier.showError("server error");
        break;
      default:
        console.log("Ran out of things to do :(");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  patch: <T>(url: string, body: {}) =>
    axios.patch<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const FinancialInstitutions = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "DateTime"
  ) =>
    requests.get<FetchData<FinancialInstitution>>(
      `FinancialInstitutions?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (id: string) =>
    requests.get<FinancialInstitution>(`/FinancialInstitutions/${id}`),
  create: (organization: FinancialInstitution) =>
    axios.post("/FinancialInstitutions", organization),
  update: (organization: FinancialInstitution) =>
    axios.put(
      `/FinancialInstitutions/${organization.financialInstitutionID}`,
      organization
    ),
  delete: (id: string) => axios.delete(`/FinancialInstitutions/${id}`),
};

const FormR = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "DateTime"
  ) =>
    requests.get<FetchData<FormR>>(
      `FormR?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (id: string) => requests.get<FormR>(`/FormR/${id}`),
  create: (formR: FormR) => axios.post("/FormR", formR),
  update: (formR: FormR) => axios.put(`/FormR/${formR.formRID}`, formR),
  reject: (vm: RejectVM) => axios.put(`/FormR/reject/${vm.id}`, vm),
  getRejectionComments: (id: string) =>
    requests.get<RejectVM>(`/FormR/get-rejection-comments/${id}`),
  delete: (id: string) => axios.delete(`/FormR/${id}`),
};
const Comments = {
  list: (
    id: string,
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "Date"
  ) =>
    requests.get<FetchData<Comment>>(
      `Comments/list/${id}?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (id: string) => requests.get<Comment>(`/Comments/${id}`),
  create: (comment: Comment) => axios.post("/Comments", comment),
  update: (comment: Comment) =>
    axios.put(`/Comments/${comment.commentID}`, comment),
  delete: (id: string) => axios.delete(`/Comments/${id}`),
};
const BusinessDocuments = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "Type"
  ) =>
    requests.get<FetchData<BusinessDocument>>(
      `documents?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (id: string) => requests.get<BusinessDocument>(`/documents/${id}`),
  create: (document: BusinessDocument) => requests.post("/documents", document),
  getProfileDocuments: (id: string, profile: DocumentProfile) =>
    requests.get<ProfileDocument[]>(
      `/documents/get-profile-documents/${id}/${profile}`
    ),
  getProfileDocumentsByType: (id: string, type: BusinessDocumentType) =>
    requests.get<ProfileDocument[]>(
      `/documents/get-document-types/${id}/${type}`
    ),
  upload: (document: FormData) =>
    axios.post("/documents/create-document", document, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  update: (document: BusinessDocument) =>
    requests.put(`/documents/${document.businessApplicationID}`, document),
  comment: (id: string, comment: CommentVM) =>
    requests.post(`/documents/comment/${id}`, comment),
  reject: (rejectVM: RejectVM) =>
    axios.post(`/documents/reject-document/${rejectVM.id}`, rejectVM),
  getRejectionComments: (id: string) =>
    requests.get<RejectVM>(`/documents/get-rejection-comments/${id}`),
  updateStatus: (id: string, status: DocumentStatus) =>
    requests.put(`/documents/update-status/${id}/${status}`, {}),
  delete: (id: string) => requests.delete(`/documents/${id}`),
};
const Applications = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "Name",
    excludePortfolio = true
  ) =>
    requests.get<FetchData<BusinessApplication>>(
      `BusinessApplications?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}&excludePortfolio=${excludePortfolio}`
    ),
  details: (id: string) =>
    requests.get<BusinessApplication>(`/BusinessApplications/${id}`),
  formT: (id: string) =>
    requests.get<FormT>(`/BusinessApplications/formt/${id}`),
  getProfile: (id: string) =>
    requests.get<BusinessProfileVM>(`/BusinessApplications/get-profile/${id}`),
  createProfile: (businessApplication: BusinessProfileVM) =>
    axios.post("/BusinessApplications/create-profile", businessApplication),
  reject: (rejectVM: RejectVM) =>
    axios.post(
      `/BusinessApplications/reject-application/${rejectVM.id}`,
      rejectVM
    ),
  getRejectionComments: (id: string) =>
    requests.get<RejectVM>(
      `/BusinessApplications/get-rejection-comments/${id}`
    ),
  create: (businessApplication: BusinessProfileVM) =>
    axios.post("/BusinessApplications", businessApplication),
  update: (businessApplication: BusinessProfileVM) =>
    axios.put(
      `/BusinessApplications/${businessApplication.id}`,
      businessApplication
    ),
  submitToManager: (id: string, businessApplication: any = {}) =>
    axios.patch(
      `/BusinessApplications/submit-to-manager/${id}`,
      businessApplication
    ),
  submitForReview: (id: string, businessApplication: any = {}) =>
    axios.patch(
      `/BusinessApplications/submit-for-review/${id}`,
      businessApplication
    ),
  submitForApproval: (id: string, businessApplication: any = {}) =>
    axios.patch(
      `/BusinessApplications/submit-for-approval/${id}`,
      businessApplication
    ),
  approve: (businessApplication: ApproveApplicationVM) =>
    axios.patch(
      "/BusinessApplications/approve-application",
      businessApplication
    ),
  delete: (id: string) => axios.delete(`/BusinessApplications/${id}`),
};

const PortFolios = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "DateTime"
  ) =>
    requests.get<FetchData<Portfolio>>(
      `Portfolio?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (id: string) => requests.get<Portfolio>(`/Portfolio/${id}`),
  getApprovalLetter: (id: string) =>
    requests.get<BusinessDocument>(`/Portfolio/get-approval-letter/${id}`),
  formT: (id: string) => requests.get<FormT>(`/Portfolio/formt/${id}`),
  create: (portfolio: UpdatePortfolioDTO) =>
    axios.post("/Portfolio", portfolio),
  update: (portfolio: UpdatePortfolioDTO) =>
    axios.put(`/Portfolio/${portfolio.id}`, portfolio),
  submitToManager: (id: string, businessApplication: any = {}) =>
    axios.patch(`/Portfolio/submit-to-manager/${id}`, businessApplication),
  submitForReview: (id: string, businessApplication: any = {}) =>
    axios.patch(`/Portfolio/submit-for-review/${id}`, businessApplication),
  submitForApproval: (id: string, businessApplication: any = {}) =>
    axios.patch(`/Portfolio/submit-for-approval/${id}`, businessApplication),
  approve: (businessApplication: ApproveApplicationVM) =>
    axios.post("/Portfolio/approve-application", businessApplication),
  reject: (rejectVM: RejectVM) =>
    axios.post(`/Portfolio/reject-application/${rejectVM.id}`, rejectVM),
  getRejectionComments: (id: string) =>
    requests.get<RejectVM>(`/Portfolio/get-rejection-comments/${id}`),
  addApplication: (id: string, applicationId: string, isAdd = false) =>
    axios.put(`/Portfolio/add-application/${id}/${applicationId}/${isAdd}`, {}),
  delete: (id: string) => axios.delete(`/Portfolio/${id}`),
};
const Logs = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "tableName"
  ) =>
    requests.get<FetchData<Audit>>(
      `Audits?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (id: string) => requests.get<Audit>(`/Audits/${id}`),
};
const Statistics = {
  usage: (startDate: Date, endDate: Date) =>
    requests.get<UsageStatistics>(
      `/Statistics?startDate=${format(
        startDate,
        "yyyy-MM-dd"
      )}&endDate=${format(endDate, "yyyy-MM-dd")}`
    ),
};
const Users = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "DateTime"
  ) =>
    requests.get<FetchData<AppUser>>(
      `users?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  login: (username: string, password: string) =>
    requests.post<AppUser>("/Users/login", { username, password }),
  resetPassword: (id: string) =>
    requests.post<AppUser>(`/Users/reset-password/${id}`, {}),
  changePassword: (id: string, vm: ResetPasswordVM) =>
    requests.put<ResetPasswordVM>(`Users/change-password/${id}`, vm),
  changeRole: (id: string, role: string) =>
    requests.put<any>(`Users/change-role/${role}/${id}`, {}),
  register: (user: RegisterDTO) =>
    requests.post<AppUser>("/Users/register", user),
  current: () => requests.get<AppUser>(`/Users/get-current`),
};
const Roles = {
  list: (
    filter = "",
    descending = false,
    page = 1,
    size = -1,
    sortBy = "Name"
  ) =>
    requests.get<FetchData<Role>>(
      `Roles?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  listClaims: (
    filter = "",
    descending = false,
    page = 1,
    size = 7,
    sortBy = "Name"
  ) =>
    requests.get<FetchData<ClaimDTO>>(
      `/Roles/get-claims?search=${filter}&sortBy=${sortBy}&descending=${descending}&size=${size}&page=${page}`
    ),
  details: (roleName: string) =>
    requests.get<Role>(`/Roles/get-role/${roleName}`),
  claimDetails: (roleName: string) =>
    requests.get<ClaimDTO[]>(`/Roles/get-claims/${roleName}`),
  addRole: (roleName: string) =>
    requests.post(`/Roles/add-role/${roleName}`, {}),
  editRole: (roleName: string, add: boolean, claim: ClaimDTO) =>
    requests.post(`/Roles/edit-role/${roleName}?add=${add}`, claim),
  delete: (name: string) => axios.delete(`/Roles/${name}`),
};

const agent = {
  Applications,
  BusinessDocuments,
  Comments,
  FinancialInstitutions,
  FormR,
  Logs,
  Users,
  Roles,
  PortFolios,
  Statistics,
};

export default agent;
