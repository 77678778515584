import { ActionTree } from "vuex";
import { AppState } from "./types";
import { RootState } from "../types";
import agent from "@/helpers/agent";
import { AppUser } from "@/models/user";
import Notifier from "@/helpers/notifier";
import isJwtTokenExpired from 'jwt-check-expiry';

export default interface LoginCredentials {
  username: string;
  password: string;
}
// export const isValidToken = (token: string | null) => {
//   let result = false;

//   try {
//     console.log(isJwtTokenExpired(token as string))
//     result = (JSON.parse(Buffer.from((token as string).split('.')[1], 'base64').toString()).exp * 1000 >= Date.now())
//   } catch (error) {
//     console.log("Could not decode token: " + token);
//   }

//   return result;
// }

export const actions: ActionTree<AppState, RootState> = {
  toggleLeftDrawer({ commit }): any {
    commit("toggleLeftDrawer");
  },
  toggleSticky({ commit }): any {
    commit("toggleSticky");
  },
  async login({ commit }, creds: LoginCredentials): Promise<any> {
    const user = await agent.Users.login(creds.username, creds.password)
    await window.localStorage.setItem('jwt', user.token)
    await commit("login", user);
     console.log(`Did login and token is ${window.localStorage.getItem('jwt')}`)
  },
  async loginWithJWT({ commit, state }): Promise<any> {
    const jwt = window.localStorage.getItem("jwt");
    if (jwt && !isJwtTokenExpired(jwt as string)) {
      const user = await agent.Users.current();
      await commit("loginWithJWT", user);
    }
  },
  logout({ commit }): any {
    commit("logout");
  },
};
