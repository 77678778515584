import agent from "@/helpers/agent";
import { AppUser } from "@/models/user";
import { MutationTree } from "vuex";
import { AppState } from "./types";


export const mutations: MutationTree<AppState> = {
  toggleLeftDrawer(state) {
    state.leftDrawer = !state.leftDrawer;
  },
  toggleSticky(state) {
    state.sticky = !state.sticky;
  },
  login(state, user: AppUser) {
    state.user = user;
  },
  loginWithJWT(state, user: AppUser) {
    state.user = user;
  },
  logout(state) {
    state.user = null;
    window.localStorage.removeItem('jwt')
  },
};
