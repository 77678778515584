import { GetterTree } from "vuex";
import { AppState } from "./types";
import { RootState } from "../types";
import { AppUser } from "@/models/user";
import isJwtTokenExpired from "jwt-check-expiry";

export const getters: GetterTree<AppState, RootState> = {
  getAppName(state): string {
    return state.appName;
  },
  getLeftDrawer(state): boolean {
    return state.leftDrawer;
  },
  getSticky(state): boolean {
    return state.sticky;
  },
  user(state): AppUser | null {
    return state.user;
  },
  role(state): string | undefined {
    return state.user?.role;
  },
  authenticated(): boolean {
    const token = window.localStorage.getItem('jwt');
    return (token && !isJwtTokenExpired(token as string)) ? true : false;
  },
  token(): string | null {
    return window.localStorage.getItem('jwt');
  },
  authHeader(): any {
    const token = window.localStorage.getItem('jwt')
    return [{
      name: 'Authorization',
      value: `Bearer ${token}`
    }]
  },
};
