import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./router";
import store from "../store";
import isJwtTokenExpired from "jwt-check-expiry";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('jwt');
  const authenticatedUser = token && !isJwtTokenExpired(token as string);
  // if (!authenticatedUser && !isJwtTokenExpired(token as string)) {
  //   store.dispatch("app/loginWithJWT")
  // }
  const role = store.getters["app/role"];
  const requiresAuth = to.matched.some((record) => record.meta.auth);
  const permittedRoles: string[] = to.meta?.permittedRoles ?? ["all"];
  const loginpath = (location.pathname + location.search).substr(1);

  if (requiresAuth && !authenticatedUser) {
    console.log("At login")
    next({ name: "Login", query: loginpath ? { from: loginpath } : {} });
  }
  else {
    const permitted = permittedRoles.includes('all') || permittedRoles.includes(role)
    console.log("At permission")
    permitted ? next() : next({ name: "Unauthorized", query: loginpath ? { from: loginpath } : {} })
  }
})

const DEFAULT_TITLE = 'Sibambiso - Empowering the business community in Eswatini';
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
export default router;
