











































































































































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { AppUser } from "@/models/user";

const namespace = "app";

@Component
export default class LeftDrawer extends Vue {
  @Getter("getLeftDrawer", { namespace })
  private leftDrawer!: boolean;
  @Action("toggleLeftDrawer", { namespace })
  private toggleLeftDrawer: any;
  @Getter("user", { namespace })
  private user!: AppUser;
  @Getter("role", { namespace })
  private role!: string | null;

  @Getter("getSticky", { namespace })
  private leftSticky!: boolean;

  get drawer() {
    return this.leftDrawer;
  }
  set drawer(value: boolean) {
    this.toggleLeftDrawer();
  }
  get userName() {
    if (this.user === null || this.user == undefined) {
      return "Please login";
    }
    return this.user.userName;
  }
  get usernameFirstLetter() {
    return this.userName.charAt(0);
  }
  buttons1 = [
    { text: "About" },
    { text: "Press" },
    { text: "Copyright" },
    { text: "Contact us" },
    { text: "Creators" },
    { text: "Advertise" },
    { text: "Developers" },
  ];
  buttons2 = [
    { text: "Terms" },
    { text: "Privacy" },
    { text: "Policy & Safety" },
    { text: "Test new features" },
  ];
  banks = [
    {
      icon: "add_circle",
      text: "Add Bank",
      url: "/create-bank",
    },
    {
      icon: "format_list_bulleted",
      text: "List Banks",
      url: "/list-bank",
    },
  ];
  applications = [
    { icon: "add", text: "New Individual", url: "/form-application" },
    { icon: "add_circle", text: "New Portfolio", url: "/form-portfolio" },
    {
      icon: "work",
      text: "Individuals",
      url: "/list-application",
    },
    {
      icon: "group_work",
      text: "Portfolios",
      url: "/list-portfolio",
    },
  ];
  financialInstitution = [
    {
      icon: "add_circle",
      text: "Add financial institution",
      url: "/form-financialinstitution",
    },
    {
      icon: "format_list_bulleted",
      text: "List  financial institution",
      url: "/list-financialinstitution",
    },
  ];
  users = [
    {
      icon: "article",
      text: "Logs",
      url: "/list-logs",
      role: "SuperAdmin",
    },
    {
      icon: "admin_panel_settings",
      text: "Roles",
      url: "/list-roles",
      role: "SuperAdmin",
    },
    { icon: "person", text: "Profile", url: "/profile-user", role: "All" },
    {
      icon: "supervisor_account",
      text: "Manage users",
      url: "/list-user",
      role: "SuperAdmin,Admin",
    },
  ];
  Security = [
    { icon: "settings", text: "Settings" },
    { icon: "support", text: "Support" },
    { icon: "feedback", text: "Feedback" },
  ];
  documents = [
    { icon: "file_present", text: "Documents", url: "/list-documents" },
  ];
}
