













import { Component, Vue } from "vue-property-decorator";
import LeftDrawer from "@/components/LeftDrawer.vue";
import Toolbar from "@/components/Toolbar.vue";
@Component({
  components: {
    LeftDrawer,
    Toolbar,
  },
})
export default class Default extends Vue {
  contentStyle = {
    color: "#555",
  };
  contentActiveStyle = {
    backgroundColor: "#eee",
    color: "black",
  };
  thumbStyle = {
    right: "2px",
    borderRadius: "5px",
    backgroundColor: "#027be3",
    width: "5px",
    opacity: 0.75,
  };
}
