







import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Loader from "./helpers/loader";

const app = namespace("app");

@Component
export default class Home extends Vue {
  @app.Action loginWithJWT: any;
  visible = false;
  get layout() {
    return `${this.$route.meta?.layout || "default"}-layout`;
  }
  mounted() {
    Loader.showLoader();
    this.visible = true;
    this.$store.dispatch("app/loginWithJWT").then(() => {
      this.loginWithJWT();
      this.visible = false;
      Loader.hideLoader();
    });
  }
}
