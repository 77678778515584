import store from "@/store";
import * as _ from "lodash";
import { hasUncaughtExceptionCaptureCallback } from "process";
import { RouteConfig } from "vue-router";

interface MetaRoute {
  name: string;
  meta: any;
}
interface RequiredMeta {
  meta: any;
  exclusionRoutes: string[];
}

function getMeta(
  routeName: string,
  requiredMeta: RequiredMeta,
  optionalMeta: MetaRoute[]
): any {
  const index = optionalMeta.findIndex(
    (i) => i.name.toLocaleLowerCase() === routeName.toLocaleLowerCase()
  );
  let meta: any = {};
  if (index !== -1) {
    meta = { ...meta, ...optionalMeta[index].meta };
  }
  if (
    !requiredMeta.exclusionRoutes
      .map((i) => i.toLowerCase())
      .includes(routeName.toLowerCase())
  ) {
    meta = { ...meta, ...requiredMeta.meta };
  }
  return meta;
}
function getRequiredMeta(key: string): any {
  const roles: string[] = [];
  const admin = new RegExp(/\b(?:approve|user|logs)\b/g, 'i');
  const reader = new RegExp(/\b(?:approve|user)\b/g, 'i');
  const user = new RegExp(/\b(?:approve|user)\b/g, 'i');
  const Manager = new RegExp(/\b(?:approve|user)\b/g, 'i');
  const reviewer = new RegExp(/\b(?:approve|user)\b/g, 'i');
  const superAdmin = new RegExp(/\b(?:Approve|user|financialInstitution|logs)\b/g, 'i');
  if (admin.test(key)) roles.push('Admin');
  if (reader.test(key)) roles.push('Reader');
  if (user.test(key)) roles.push('User');
  if (Manager.test(key)) roles.push('Manager');
  if (reviewer.test(key)) roles.push('Reviewer');
  if (superAdmin.test(key)) roles.push('SuperAdmin');
  if (roles.length === 0) roles.push('all')

  return { auth: roles.length > 0, permittedRoles: roles };
}
// add default route.
function computeRoutes(
  home: string,
  requiredMeta: RequiredMeta,
  optionalMeta: MetaRoute[]
): RouteConfig[] {
  const routes: RouteConfig[] = [];
  // compute routes dynamically.
  const req = require.context("@/views", true, /\.(vue)$/i, "lazy");
  req.keys().map((key) => {
    const path = `/${key
      .slice(2)
      .replace(".vue", "")
      .split("/")
      .reverse()
      .join("-")
      .toLocaleLowerCase()}`;
    const name = _.upperFirst(_.camelCase(path));
    const newRequireMeta = { ...requiredMeta, meta: getRequiredMeta(key) }
    const meta = getMeta(name, newRequireMeta, optionalMeta);
    routes.push({
      path: name === home ? "/" : path,
      name,
      component: () => import(`@/views/${key.slice(2)}`),
      meta,
      // beforeEnter: (to, from, next) => {
      //   if (!requiredMeta.exclusionRoutes.includes(`${to.name}`)) {
      //     const role = store.getters["app/role"];
      //     const permitted = meta.permittedRoles.includes('all') || meta.permittedRoles.includes(role)
      //     if (permitted) next()
      //     else next({ name: "Unauthorized" })
      //   }
      // }
    });
  });
  return routes;
}

const routes = computeRoutes(
  "Login",
  { meta: { requiresAuth: true }, exclusionRoutes: ["Login", "Register", "Resetpassword", "Unauthorized"] },
  [
    { name: "Login", meta: { layout: "fullscreen" } },
    { name: "Register", meta: { layout: "fullscreen" } },
    { name: "Resetpassword", meta: { layout: "fullscreen" } },
  ]
);
console.log(routes)
export default routes;